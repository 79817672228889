import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });

        userService.login(username, password)
            .then(
                user => {
                    // console.log(user)
                    if(user.status == 'success'){
                        commit('loginSuccess', user);
                        router.push('/');
                    }
                    else{
                        alert(user.msg)
                    }
                },
                error => {
                    commit('loginFailure', error);
                    // dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },

    validate() {
      let user = JSON.parse(localStorage.getItem('user'));
      let new_date = new Date()
      let timestamp = parseInt((Date.parse(new_date)).toString().slice(0, -3));
      // let counter = 0;

      if(user){
        if (timestamp > user.Expired_at ) {
            // console.log('log him out')
            // clearInterval(id);
            return false
          } else {
            // counter++;
            // console.log(user.Expired_at , timestamp)
            // console.log('continue')
            return true
          }
      }
      

      // var id = setInterval(checkExpiredTime, 500);
      // function checkExpiredTime() {
      //   if(counter == 3){
      //     console.log('log him out')
      //     clearInterval(id);
      //     self.logout();
      //     self.$router.push({
      //       path: "/login",
      //     });
      //   }
      //   else{
          
      //   }
        
      // }
    },

    //return user list
    // async user_list({ commit }, user) {
    //     const list = await userService.postAction('users/list',user)
    //     commit('setUserList', list.data);
    // },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },

    // setUserList(state, list){
    //     state.user_list = list
    // }
};


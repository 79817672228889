import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'

import i18n from './i18n'
import store from '@/state/store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery
Vue.prototype.$ = jQuery;
// Vue.prototype.$ = $jquery

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import Notifications from 'vue-notification'
Vue.use(Notifications)

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import moment from 'moment'
Vue.prototype.moment = moment

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import axios from 'axios'
const base = axios.create({
  baseURL: process.env.VUE_APP_GLOBALURL, 
});

Vue.prototype.$http = base;

Vue.prototype.$http.interceptors.request.use(
  config => {
      let user = JSON.parse(localStorage.getItem('user'));
      // const config = {
      //     headers: { Authorization: `Bearer ${user.token}` }
      // };
      let accessToken = user.token;
      if (accessToken) {
          config.headers = Object.assign({
              Authorization: `Bearer ${accessToken}`
          }, config.headers);
      }
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);

Vue.prototype.$http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('http response', response)
   
    if(response.data.message == 'Logout' || (response.data.code == 0 && response.data.session_timeout)){
      store.dispatch('authfack/logout')
      router.push(
        {
          path: "/login",
        }
      ).catch(error => {
   console.info(error.message)
});
      // return Promise.reject('session_timeout');
    }
    else{
      return response;
    }

    
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

// Vue.prototype.$http = axios

// import { initFirebaseBackend } from './helpers/firebase/authUtils';

// import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   configureFakeBackend();
// }

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
